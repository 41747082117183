html {
  min-height: 100vh;
}

body {
  font-family: 'Roboto', Arial, sans-serif;
  line-height: 1;
}

.ScrollSection {
  min-height: 100vh;
}

.Splash {
  background: center / cover no-repeat url('/cover.jpg');
  display: flex;
  align-items: center;
  flex-direction: column;
  height: 100vh;
  justify-content: center;
}

.Splash-wrapper {
  display: flex;
  flex-direction: column;
  text-align: center;
}

.Splash-title {
  background: #cc2855;
  color: #fff;
  display: inline-block;
  font-size: 80px;
  font-weight: 900;
  margin: 0;
  padding: 0.2em;
}

.Splash-subtitle {
  background: #fff;
  color: #cc2855;
  font-size: 36px;
  margin: 10px 0 0;
  padding: 8px 18px;
  text-transform: lowercase;
}

.Splash-sticker {
  align-self: flex-end;
  background: #cc2855;
  color: #fff;
  font-size: 20px;
  padding: 4px 8px;
  position: relative;
  right: -18px;
  transform: rotate(-8deg);
}

.Splash-okay {
  font-size: 18px;
  margin-top: 24px;
  text-align: left;
}

.Splash-arrow {
  animation: animation-bounce 1000ms ease-in infinite;
  display: inline-block;
  margin-left: 0.5em;
}

.Splash-nav {
  top: 20px;
  font-family: 'Roboto Condensed', Arial, sans-serif;
  font-size: 16px;
  list-style: none;
  margin: 0;
  padding: 0;
  position: absolute;
}

.Splash-navItem {
  display: inline;
}
.Splash-navItem:not(:first-child)::before {
  color: #cc2855;
  content: ' / ';
  font-weight: 600;
  margin: 0 5px;
}

.Splash-navLink {
  color: #cc2855;
  font-weight: 600;
  padding: 2px 4px;
  text-decoration: none;
  text-transform: uppercase;
}
.Splash-navLink:hover {
  background-color: #fff;
  text-decoration: underline;
}

.AboutUs,
.RSVP {
  background: #cc2855;
  color: #fff;
}

.AboutUs,
.Schedule,
.RSVP {
  align-items: center;
  display: flex;
  font-size: 24px;
  justify-content: center;
}

.AboutUs {
  display: flex;
}
.AboutUs-gallery {
  width: 30vw;
  list-style-type: none;
  margin: 0;
  padding: 0;
}
.AboutUs-galleryImage {
  display: block;
  width: 100%;
}
.AboutUs-content {
  flex-grow: 1;
  text-align: center;
}

.Schedule {
  display: block;
  text-align: center;
  padding-bottom: 30px;
}

.Schedule-section {
  display: flex;
  position: relative;
}
.Schedule-section:not(:nth-child(2)) {
  padding-top: 90px;
}
@media (min-width: 1200px) {
  .Schedule-section:nth-child(even) {
    flex-flow: row-reverse;
  }
}

.Schedule-sectionTitle {
  position: absolute;
  top: 0;
  left: 50%;
  transform: translateX(-50%);
  width: 100%;
}

.Schedule-timeline {
  display: flex;
  flex-direction: column;
  padding: 0 0 0 150px;
  text-align: left;
  width: 50%;
}
@media (min-width: 1200px) {
  .Schedule-section:nth-child(even) .Schedule-timeline {
    padding: 0 150px 0 0;
    text-align: right;
  }
}

.Schedule-timelineEvent {
  border: 4px solid #cc2855;
  border-width: 0 0 0 4px;
  font-size: 16px;
  font-weight: 200;
  margin: 0 auto 0 -2px;
  max-width: 650px;
  padding: 10px 20px;
  position: relative;
}
@media (min-width: 1200px) {
  .Schedule-section:nth-child(even) .Schedule-timelineEvent {
    border-width: 0 4px 0 0;
    margin: 0 -2px 0 auto;
  }
}

.Schedule-timestamp {
  align-items: center;
  display: inline-flex;
  padding: 0 15px;
  position: absolute;
  right: 100%;
}
@media (min-width: 1200px) {
  .Schedule-section:nth-child(even) .Schedule-timestamp {
    left: 100%;
    right: auto;
  }
}

.Schedule-timestamp::after {
  background: #cc2855;
  border-radius: 5px;
  content: " ";
  display: inline-block;
  height: 10px;
  position: absolute;
  right: -3px;
  width: 10px;
}
@media (min-width: 1200px) {
  .Schedule-section:nth-child(even) .Schedule-timestamp::after {
    left: -3px;
    right: auto;
  }
}

.Schedule-majorEvent {
  font-size: 24px;
  padding-top: 30px;
}
.Schedule-miniEvent {
  display: inline-block;
  font-weight: 900;
  margin: 0;
}

.Schedule-event {
  background: #cc2855;
  color: #fff;
  display: inline-block;
  font-weight: 900;
  margin: 0;
  margin-right: 10px;
  padding: 4px 8px;
}
.Schedule-location {
  font-size: 18px;
  font-style: italic;
  font-weight: 900;
}
.Schedule-majorEvent .Schedule-timestamp {
  padding: 4px 20px;
  font-weight: 900;
}

.Schedule-majorEvent .Schedule-timestamp::after {
  background: #fff;
  border: 5px solid #cc2855;
  border-radius: 10px;
  box-sizing: border-box;
  content: " ";
  display: inline-block;
  height: 20px;
  position: absolute;
  right: -8px;
  width: 20px;
}
@media (min-width: 1200px) {
  .Schedule-section:nth-child(even) .Schedule-majorEvent .Schedule-timestamp::after {
    left: -8px;
    right: auto;
  }
}

.Schedule-majorEvent .Schedule-location {
  font-weight: 900;
}

.Schedule-descriptionHeader {
  margin-bottom: 15px;
}
.Schedule-description {
  font-size: 16px;
  line-height: 1.3em;
}

.Schedule-map {
  max-width: 700px;
  min-width: 400px;
  width: 30vw;
}
.Schedule-section:nth-child(even) .Schedule-sideContent .Schedule-map {
  margin-right: auto;
}
.Schedule-section:nth-child(odd) .Schedule-sideContent .Schedule-map {
  margin-left: auto;
}

.Schedule-sideContent {
  flex: 1 1 auto;
  margin: 0 20px;
  min-width: 1px;
  position: static;
}

.Schedule-inlineContent {
  margin-left: -999em;
  position: absolute;
  visibility: hidden;
}

@media (max-width: 1199px) {
  .Schedule-timeline {
    box-sizing: border-box;
    margin: 0 auto;
    width: 800px;
  }
  .Schedule-sideContent {
    margin-left: -999em;
    position: absolute;
    visibility: hidden;
  }
  .Schedule-inlineContent {
    margin: 0;
    position: static;
    visibility: visible;
  }
}

.Schedule-mapContainer {
  position: relative;
  padding-top: 75%;
}
.Schedule-mapFrame {
  height: 100%;
  left: 0;
  position: absolute;
  top: 0;
  width: 100%;
}

.RSVP {
  padding-top: 30px;
}
.RSVP-formFrame {
  width: 100vw;
}

@keyframes animation-bounce {
  0% {
    transform: translateY(-1px);
  }
  25% {
    transform: translateY(-10px);
  }
  75% {
    transform: translateY(2px);
  }
}
